<template>
<b-card no-body>
  <b-tabs pills card vertical>
    <b-tab title="Details" active>
          <b-card-text>
            <div class="form-group">
              <label>Location of device</label>
              <b-form-input
                id="device-location"
                type="text"
                class="form-control form-control-solid form-control-lg"
                placeholder="Where is this device located?"
                v-model="device_location"
                :state="deviceLocationValidation"
                aria-describedby="device-location-feedback"
                trim
              />
              <b-form-invalid-feedback id="device-location-feedback">
                Enter at least 5 letters
              </b-form-invalid-feedback>
              <span class="form-text text-muted"
                >Where is this device located? ie Kitchen, garage, etc</span
              >
            </div>

            <!-- <div class="form-text">Name of the device</div> -->
            <div class="form-group">
              <label>Name of the device</label>
              <b-form-input
                type="text"
                class="form-control form-control-solid form-control-lg"
                placeholder="Name"
                value="Bulb"
                v-model="current_device.object"
                :state="deviceNameValidation"
                aria-describedby="device-name-feedback"
                trim
              />
              <b-form-invalid-feedback id="device-name-feedback">
                Enter at least 5 letters
              </b-form-invalid-feedback>
              <span class="form-text text-muted"
                >Write the name of the device, ie bulb, lamp, leds etc
              </span>
            </div>

            <!-- <div class="form-text">Token</div> -->
            <div class="form-group">
              <label>Token</label>
              <div>
                <b-link
                  class="form-control form-control-solid form-control-lg"
                  href="#foo"
                  disabled
                >
                  {{ current_device.token }}
                </b-link>
              </div>
            </div>

            <!-- <div class="form-text">Secret Token</div> -->
            <div class="form-group">
              <label>Secret Token</label>
              <div>
                <b-link
                  class="form-control form-control-solid form-control-lg"
                  href="#foo"
                  disabled
                >
                  {{ current_device.secret_token }}
                </b-link>
              </div>
            </div>

            <!-- <div class="form-text">Description</div> -->
            <div class="form-group">
              <label>Description</label>
              <b-form-textarea
                id="textarea"
                v-model="current_device.description"
                placeholder="Enter something that describes your device..."
                rows="3"
                :state="deviceDescriptionValidation"
                max-rows="6"
              ></b-form-textarea>
              <b-form-invalid-feedback id="device-name-feedback">
                Enter at least 10 letters
              </b-form-invalid-feedback>
            </div>

            <b-button variant="success" v-on:click="update" :disabled="btnUpdate==true">
              <b-spinner small v-show="btnUpdate == true"></b-spinner>
              Update
            </b-button>

          </b-card-text>
        </b-tab>
        <b-tab title="Notifications" v-if="enableNotifications">
              <b-card-text>
                <b-form-group>
                  <template v-slot:label>
                    Notifications activated for this device, if notifications
                    are enabled, this device will start broadcasting
                    <b>online/offline</b> events
                  </template>
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="notifications_selected"
                    name="flavour-2"
                    stacked
                    size="lg"
                  >
                    <b-form-checkbox
                      value="twitter"
                      size="lg"
                      >Twitter</b-form-checkbox
                    >
                    <b-form-checkbox
                      value="fbinbox"
                      size="lg"
                      >Facebook Messenger</b-form-checkbox
                    >
                    <b-form-checkbox
                      value="email"
                      size="lg"
                      >E-mail</b-form-checkbox
                    >
                    <b-form-checkbox
                      value="telegram"
                      size="lg"
                      >Telegram</b-form-checkbox
                    >
                  </b-form-checkbox-group>
                </b-form-group>
                <div class="form-group">
                  <label>Is this device public?</label>
                  <b-form-checkbox
                    v-model="device_public"
                    name="check-button"
                    switch
                  >
                    Select availability
                    <b
                      >({{ current_device.is_public == "0" ? "No" : "Yes" }})</b
                    >
                  </b-form-checkbox>
                </div>

                <b-button variant="success" v-on:click="update" :disabled="btnUpdate==true">
                <b-spinner small v-show="btnUpdate == true"></b-spinner>
                  Update
                </b-button>

              </b-card-text>
            </b-tab>

            <b-tab title="Actions/Props" v-if="editable">
              <b-card-text>
                Actions are reserved words that you can use to expose
                functionality and should do something on a device, for instance
                <b>ON</b> or <b>OFF</b>.
                <br />
                Props are reserved words that you send from your device to the
                Suitch network to store small information, like small strings or
                numbers.
                <br />
                For instance if you create an action to send degrees to a device
                whose name is <b>set_temperature</b>
                but you also need to save the temperature in the Suitch network,
                you will need to create a prop for example
                <b>current_temperature</b>.
                <br />
                By doing so, now from your device you will be able to send to
                the Suitch network, data values that can be seen as charts. That
                information can also be used in the "Charting" menu to combine
                more complex interactions.
                <b-card>
                  <template v-slot:header>
                    <h6 class="mb-0">Create new Action or Prop</h6>
                  </template>
                  <div class="form-group">
                    <label>Name:</label>
                    <b-form-input
                      id="action-prop-formal-name"
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Formal name"
                      v-model="action_prop_formal_name"
                      :state="actionPropFormalValidation"
                      aria-describedby="action-prop-formal-feedback"
                      trim
                    />
                    <b-form-invalid-feedback id="action-prop-formal-feedback">
                      Enter at least 5 letters
                    </b-form-invalid-feedback>
                    <span class="form-text text-muted"
                      >Formal representation for this element,
                      <b>for example: Turn on</b></span
                    >
                  </div>
                  <div class="form-group">
                    <label>Technical name:</label>
                    <b-form-input
                      id="action-prop-name"
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Technical name"
                      v-model="action_prop_name"
                      :state="actionPropValidation"
                      aria-describedby="action-prop-feedback"
                      trim
                    />
                    <b-form-invalid-feedback id="action-prop-feedback">
                      Enter at least 4 letters
                    </b-form-invalid-feedback>
                    <span class="form-text text-muted"
                      >Create a name without spaces, this is the technical name
                      that will be used to send/receive information,
                      <b>for example: turn_on</b></span
                    >
                  </div>
                  <div class="form-group">
                    <label>Type:</label>
                    <select
                      name="action-prop-type"
                      class="form-control form-control-solid form-control-lg"
                      v-model="action_prop_type"
                      :state="actionTypeValidation"
                      aria-describedby="action-type-feedback"
                    >
                      <option value="" disabled>Select type of element</option>
                      <option value="action">Action</option>
                      <option value="prop">Prop</option>
                    </select>
                    <b-form-invalid-feedback id="action-type-feedback">
                      Select an option to create
                    </b-form-invalid-feedback>
                    <span class="form-text text-muted"
                      >What type of data element are you creating?</span
                    >
                  </div>

                  <div class="form-group" v-if="action_prop_type=='prop'">
                    <label>Units:</label>
                    <select
                      name="units-prop"
                      class="form-control form-control-solid form-control-lg"
                      v-model="units_prop"
                      :state="unitsPropValidation"
                      aria-describedby="units-feedback"
                    >
                    <option value="" disabled>Please select a units option</option>
                    <b-form-select-option-group label="Electrical Units">
                      <b-form-select-option value="Amp">Ampere</b-form-select-option>
                      <b-form-select-option value="Volts">Volts</b-form-select-option>
                      <b-form-select-option value="Watts">Watts</b-form-select-option>
                      <b-form-select-option value="Ohms">Ohms</b-form-select-option>
                      <b-form-select-option value="Joule">Joule</b-form-select-option>
                      <b-form-select-option value="kW-h">Kilowatt-hour</b-form-select-option>
                      <b-form-select-option value="Ah">Ampere-hour</b-form-select-option>
                      <b-form-select-option value="F">Farad</b-form-select-option>
                      <b-form-select-option value="dBW">Decibel-Watt</b-form-select-option>
                      <b-form-select-option value="C">Coulomb</b-form-select-option>
                    </b-form-select-option-group>
                    <b-form-select-option-group label="Length Units">
                      <b-form-select-option value="mm">Milimeter</b-form-select-option>
                      <b-form-select-option value="cm">Centimeter</b-form-select-option>
                      <b-form-select-option value="m">Meters</b-form-select-option>
                      <b-form-select-option value="Km">Kilometer</b-form-select-option>
                      <b-form-select-option value="in">Inch</b-form-select-option>
                      <b-form-select-option value="ft">Feet</b-form-select-option>
                      <b-form-select-option value="yd">Yard</b-form-select-option>
                    </b-form-select-option-group>
                    <b-form-select-option-group label="Units of temperature">
                      <b-form-select-option value="°C">Celsius</b-form-select-option>
                      <b-form-select-option value="°F">Farenheit</b-form-select-option>
                      <b-form-select-option value="°K">Kelvin</b-form-select-option>
                    </b-form-select-option-group>
                    <b-form-select-option-group label="Units of Pressure">
                      <b-form-select-option value="Pa">Pascal</b-form-select-option>
                    </b-form-select-option-group>
                    <b-form-select-option-group label="Units of Humidity">
                      <b-form-select-option value="%">Relative Humidity</b-form-select-option>
                    </b-form-select-option-group>
                    <b-form-select-option-group label="Units of acceleration">
                      <b-form-select-option value="g">Accelaration of gravity(g)</b-form-select-option>
                    </b-form-select-option-group>
                    <b-form-select-option-group label="Units of magnetic induction">
                      <b-form-select-option value="Gs">Gauss</b-form-select-option>
                    </b-form-select-option-group>
                    <b-form-select-option-group label="Units for gyroscope">
                      <b-form-select-option value="mV/deg/s">mV per Degrees per second(mV/deg/s)</b-form-select-option>
                    </b-form-select-option-group>
                    </select>
                    <b-form-invalid-feedback id="units-feedback">
                      Select an option to create
                    </b-form-invalid-feedback>
                    <span class="form-text text-muted"
                      >Enter a unit of measure for the created prop</span
                    >
                  </div>


                  <template v-if="this.action_prop_type.length == 0 || !actionPropValidation || this.action_prop_formal_name.length <= 5">
                    <b-button variant="success" disabled>
                      Create</b-button
                    >
                  </template>
                  <template v-else>
                    <b-button variant="success" v-on:click="createAction" :disabled="btnCreateAct==true">
                      <b-spinner small v-show="btnCreateAct == true"></b-spinner>
                      Create</b-button
                    >                
                  </template> 
                </b-card>
              </b-card-text>
              <div>
                <b-table
                  striped
                  hover
                  :items="action_prop_table_items"
                  :fields="action_prop_table_fields"
                >
                  <template #cell(command)="row">
                    {{ row.item.command }} ({{ row.item.command_data }})
                  </template>
                  <template #cell(is_only_question)="row">
                    {{ row.item.is_only_question == "0" ? "Prop" : "Action" }}
                  </template>
                  <template #cell(actions)="row">
                    <b-button
                      :ref="'btn-trash-'+row.item.id"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      v-on:click.prevent="removeDeviceAction(row.item.id)"
                    >
                    <b-spinner small v-show="spinnersToShow.indexOf(row.item.id)!=-1"></b-spinner>
                      <span class="svg-icon svg-icon-md svg-icon-primary" >
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg src="media/svg/icons/General/Trash.svg" v-if="spinnersToShow.indexOf(row.item.id)==-1"/>
                        <!--end::Svg Icon-->
                      </span>
                    </b-button>
                  </template>
                </b-table>
              </div>
            </b-tab>

            <b-tab title="Schedule" v-if="enableSchedule"
              ><b-card-text>
                You can set a schedule for a particular Action, this automation
                only applies to Actions.
                <b-card>
                  <template v-slot:header>
                    <h6 class="mb-0">Automation</h6>
                  </template>
                  <div class="form-group">
                    <label>Time:</label>
                    <b-form-input
                      id="aut_datetime"
                      type="time"
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Time"
                      v-model="aut_datetime"
                      :state="autDateTimeValidation"
                      aria-describedby="aut_datetime-feedback"
                      trim
                    />
                    <b-form-invalid-feedback id="aut_datetime-feedback">
                      Enter the time
                    </b-form-invalid-feedback>
                    <span class="form-text text-muted">Specify the time</span>
                  </div>
                  <div class="form-group">
                    <label>Action:</label>
                    <select
                      name="select-action"
                      class="form-control form-control-solid form-control-lg"
                      v-model="action_sche"
                      :state="enterActValidation"
                      aria-describedby="enter-act-feedback"
                    >
                      <option :value="-1"
                      class="d-flex justify-content-between align-items-center"
                      disabled>
                      Select an action </option>
                      <option 
                      :value="obj.id"
                      v-for="(obj, i) in action_prop_table_items"
                      :key="i"
                      class="d-flex justify-content-between align-items-center">
                      {{ obj.command_data }}</option>
                    </select>
                    <b-form-invalid-feedback id="enter-act-feedback">
                      Select an action to automate
                    </b-form-invalid-feedback>
                    <span class="form-text text-muted"
                      >Select an action to automate</span
                    >
                  </div>

                  <template v-if="this.aut_datetime.length <= 4 || this.action_sche == -1">
                    <b-button variant="success" disabled>
                    Create</b-button
                  >
                  </template>
                  <template v-else>
                  <b-button variant="success" v-on:click="createSchedule" :disabled="btnCreateSche==true">
                  <b-spinner small v-show="btnCreateSche == true"></b-spinner>
                    Create</b-button
                  >
                  </template>
                </b-card>
                <b-table
                  striped
                  hover
                  :items="schedule_prop_table_items"
                  :fields="schedule_prop_table_fields"
                >
                  <template #cell(command)="row">
                    {{ row.item.command }} ({{ row.item.command_data }})
                  </template>

                  <template #cell(schedule)="row">
                    {{ row.item.schedule | formatDate }}
                  </template>

                  <template #cell(actions)="row">
                    <b-button
                      :ref="'btn-trash-schedule-'+row.item.id"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      v-on:click.prevent="removeDeviceSchedule(row.item.id)"
                    >
                    <b-spinner small v-show="spinnersToShow.indexOf(row.item.id)!=-1"></b-spinner>
                      
                      <span class="svg-icon svg-icon-md svg-icon-primary" v-if="spinnersToShow.indexOf(row.item.id)==-1">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                        <inline-svg src="media/svg/icons/General/Trash.svg" />
                        <!--end::Svg Icon-->
                      </span>
                    </b-button>
                  </template>
                </b-table>
              </b-card-text></b-tab
            >
          </b-tabs>
        </b-card>
</template>

<script>
import {
  FETCH_SINGLE_DEVICE,
  UPDATE_DEVICE,
  FETCH_DEVICE_ACTIONS,
  FETCH_DEVICE_SCHEDULES,
  CREATE_SCHEDULE,
  CREATE_ACTION,
  DELETE_DEVICE_ACTIONS,
  DELETE_DEVICE_SCHEDULES,
} from "@/core/services/store/devices.module";
import { SOURCES } from "@/core/enums/sources.enum";
import { DEVICES_TECHNICAL_NAMES } from "@/core/enums/devices-technical-names.enum";
import Swal from "sweetalert2";
export default {
  name: "suitch-details-widget",
  components: {},
  props: {
    device: null,
    editable:{
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current_device: {object:''},
      device_description: "",
      device_name: "",
      device_location: "",
      device_token: "",
      secret_token: "",

      action_prop_table_fields: ["command",{
            key: 'is_only_question',
            label: 'Type',
            // Variant applies to the whole column, including the header and footer
          }, "actions"],
      action_prop_table_items: [],
      schedule_prop_table_fields: ["command", "schedule", "actions"],
      schedule_prop_table_items: [],
      global_wizard: {},
      notifications_selected: [],

      device_public: false,
      action_prop_name: "",
      action_prop_formal_name: "",
      action_prop_type: "",
      aut_datetime: "",
      units_prop: "",
      action_sche: -1,

      btnUpdate: false,
      btnCreateAct: false,
      btnCreateSche: false,
      spinnersToShow:"",
      enableSchedule: true,
      enableNotifications: true,
    };
  },
  mounted() {
    const device_token = this.$route.params.token;
    this.$store
      .dispatch(FETCH_SINGLE_DEVICE, { device_token })
      // go to which page after successfully login
      .then((data) => {
        // download devices list
        this.current_device = data;
        if(this.current_device.version == 'trackerv1'){
          this.enableNotifications = false;
          this.enableSchedule = false;
        }
        this.device_location = this.current_device.location;
        this.device_public = this.current_device.is_public == '0' ? false : true;
        if(this.current_device.notify_twitter == 1){
          this.notifications_selected.push ("twitter");
        }
        if(this.current_device.notify_telegram == 1){
          this.notifications_selected.push ("telegram");
        }
        if(this.current_device.notify_fbinbox == 1){
          this.notifications_selected.push ("fbinbox");
        }
        if(this.current_device.notify_email == 1){
          this.notifications_selected.push ("email");
        }
      });
      this.$store
      .dispatch(FETCH_DEVICE_ACTIONS, { device_token })
      // go to which page after successfully login
      .then((data) => {
        // download devices list
        this.action_prop_table_items = data;
      });
      this.$store
      .dispatch(FETCH_DEVICE_SCHEDULES, { device_token })
      // go to which page after successfully login
      .then((data) => {
        // download devices list
        this.schedule_prop_table_items = data;
      });
  },
  methods: {
    update(e) {
      e.preventDefault();
      // here we create the object to send
      this.btnUpdate = true;
      const current_token = this.current_device.token;
      this.$store
        .dispatch(UPDATE_DEVICE, {current_token,
          source: SOURCES.WEB,
          anet_device: {
            object: this.current_device.object,
            token: this.current_device.token,
            secret_token: this.current_device.secret_token,
            location: this.device_location,
            description: this.current_device.description,
            is_public: this.device_public == false ? "0" : "1",
            notify_email:
              this.notifications_selected.indexOf("email") != -1 ? "1" : "0",
            notify_twitter:
              this.notifications_selected.indexOf("twitter") != -1 ? "1" : "0",
            notify_telegram:
              this.notifications_selected.indexOf("telegram") != -1 ? "1" : "0",
            version: DEVICES_TECHNICAL_NAMES[this.device_type],
            notify_fbinbox:
              this.notifications_selected.indexOf("messenger") != -1 ? "1" : "0",
          },
        })
        .then((resultOfUpdate) => {
          this.btnUpdate = false;
          if (resultOfUpdate.result == "success") {
            this.$bvToast.toast("The device has been updated", {
              title: `Result`,
              variant: "success",
              solid: true,
            });
            this.$store
            .dispatch(FETCH_SINGLE_DEVICE, { device_token:current_token })
            // go to which page after successfully login
            .then((data) => {
              // download devices list
              this.current_device = data;
             });
          }
          else {
            this.$$bvToast.toast("There was a problem updating the device", {
              title: `Result`, 
              variant: "danger", 
              solid: true,
            });
          }
        })
        .catch((error) => {
          this.btnUpdate = false;
          error;
          Swal.fire({
          title: "",
          text: "The application found an issue on the last operation",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
        });
        });
      },   
    createAction(e) {
      e.preventDefault();
      // here we create the object to send
      this.btnCreateAct = true;
      const current_token = this.current_device.token;
      this.$store
        .dispatch(CREATE_ACTION, {
          current_token,
          source: SOURCES.WEB,
          anet_command: {
            command_data: this.action_prop_name,
            command: this.action_prop_formal_name,
            unit: this.units_prop,
            is_only_question: this.action_prop_type == "prop" ? "0" : "1",
          },
        })
        .then((resultOfCreateAct) => {
          resultOfCreateAct;
          this.btnCreateAct = false;
          this.$store
            .dispatch(FETCH_DEVICE_ACTIONS, { device_token:current_token })
            // go to which page after successfully login
            .then((data) => {
              // download devices list
              this.action_prop_table_items = data;
            });
            this.action_prop_name = "";
            this.action_prop_formal_name = "";
            this.action_prop_type = "";
          this.$bvToast.toast("The action has been created", {
            title: `Result`,
            variant: "success",
            solid: true,
          });
        });
    },
    createSchedule(e) {
      e.preventDefault();
      // here we create the object to send
      this.btnCreateSche = true;
      let base_time = new Date();
      base_time.setHours(parseInt(this.aut_datetime.substr(0,2)),parseInt(this.aut_datetime.substr(3,2)));
      const current_token = this.current_device.token;
      this.$store
        .dispatch(CREATE_SCHEDULE, {
          current_token,
          source: SOURCES.WEB,
            command: this.action_sche,
            schedule: base_time.getUTCHours()+":"+base_time.getUTCMinutes(),
        })
        .then((resultOfCreateSche) => {
          this.btnCreateSche = false;
          if (resultOfCreateSche.result == "success") {
            this.$store
              .dispatch(FETCH_DEVICE_SCHEDULES, { device_token:current_token })
              // go to which page after successfully login
              .then((data) => {
                // download devices list
                this.schedule_prop_table_items = data;
              });
              this.action_sche = -1;
              this.aut_datetime = "";
            this.$bvToast.toast("The schedule has been created", {
              title: `Result`,
              variant: "success",
              solid: true,
            });
          }
        });
    },
    removeDeviceAction(action_id) {
      // here we create the object to send
      this.$refs["btn-trash-"+action_id].disabled=true;
      let spinners=this.spinnersToShow.split(",");
      spinners.push(String(action_id));
      this.spinnersToShow = spinners.join(",");
      this.$forceUpdate();
      const current_token = this.current_device.token;
      const current_action_id = action_id;
      this.$store
        .dispatch(DELETE_DEVICE_ACTIONS, { 
          current_token, 
          current_action_id })
        .then((resultOfDeleteAct) => {
          // if it was removed, reload the list of commands, so that
          // it looks like real time
          this.$refs["btn-trash-"+action_id].disabled=false;
          this.$forceUpdate();
          const mycurrent_token = this.$route.params.token;
          this.$store
            .dispatch(FETCH_DEVICE_ACTIONS, { device_token:mycurrent_token })
            // go to which page after successfully login
            .then((data) => {
              // download devices list
              this.action_prop_table_items = data;
            });
          if (resultOfDeleteAct.result == "success") {
            this.$bvToast.toast("The action has been removed", {
              title: `Result`,
              variant: "success",
              solid: true,
            });
          }
          else {
            this.$$bvToast.toast("There was a problem removing the action", {
              title: `Result`, 
              variant: "danger", 
              solid: true,
            })
          }
        });
    },
    removeDeviceSchedule(schedule_id) {
      // here we create the object to send
      const current_token = this.current_device.token;
      const current_schedule_id = schedule_id;
      this.$refs["btn-trash-schedule-"+schedule_id].disabled=true;
      let spinners=this.spinnersToShow.split(",");
      spinners.push(String(schedule_id));
      this.spinnersToShow = spinners.join(",");
      this.$forceUpdate();
      this.$store
        .dispatch(DELETE_DEVICE_SCHEDULES, {
          current_token,
          current_schedule_id})
        .then((resultOfDeleteSche) => {
          // if it was removed, reload the list of commands, so that
          // it looks like real time
          const mycurrent_token = this.$route.params.token;
          this.$store
            .dispatch(FETCH_DEVICE_SCHEDULES, { device_token:mycurrent_token })
            // go to which page after successfully login
            .then((data) => {
              // download devices list
              this.schedule_prop_table_items = data;
            });
          if (resultOfDeleteSche.result == "success") {
            this.$bvToast.toast("The schedule has been removed", {
              title: `Result`,
              variant: "success",
              solid: true,
            });
          }
          else {
            this.$$bvToast.toast("There was a problem removing the schedule", {
              title: `Result`, 
              variant: "danger", 
              solid: true,
            })
          }
        });
      },
    },
    computed: {
    deviceLocationValidation() {
      if(this.device_location == undefined || this.device_location==null){
        return false;
      }
      return this.device_location.length > 5 ? true : false;
    },
    deviceNameValidation() {
      if (this.current_device == null || this.current_device == undefined) {
        return false;
      }
      return this.current_device.object.length > 5 ? true : false;
    },
    deviceDescriptionValidation() {
      if (this.current_device.device_description==null) {
        return false;
      }
      return this.current_device.description.length > 10 ? true : false;
    },
    actionPropFormalValidation() {
      return this.action_prop_formal_name.length > 5 ? true : false;
    },
    actionPropValidation() {
      return (
        (this.action_prop_name.length >= 4 ? true : false) &&
        this.action_prop_name.indexOf(" ") == -1
      );
    },
    actionTypeValidation() {
      return this.action_prop_type.length == 0 ? false : true;
    },
    autDateTimeValidation() {
      return this.aut_datetime.length > 4 ? true : false;
    },
    enterActValidation() {
      return this.action_sche == -1 ? false : true;
    },
    unitsPropValidation() {
      return this.units_prop.length > 0 ? true : false;
    }
  }, 
};
</script>